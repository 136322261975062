<template>
  <div>
    <accounts-table-external-gql
      :columns="columns"
      :per-page-max="1"
    />
  </div>
</template>
<script>
import AccountsTableExternalGql from '@/components/entity/account-gql/AccountsTableExternalGql.vue'

export default {
  components: {
    AccountsTableExternalGql,
  },
  computed: {
    columns() {
      return [
        'show_details',
        {
          name: 'accountNumber', label: 'Account number', filterable: 'eq', visible: false,
        },
        {
          name: 'user',
          label: 'User',
        },
        {
          name: 'account_type_name',
          label: 'Account type',
        },
        {
          name: 'account_subtype_name',
          label: 'Account phase',
        },
        {
          name: 'account_subtype_name',
          label: 'Phase',
        },
        {
          name: 'rules',
          sortable: true,
          label: 'ID|DD|TD|FW|W|PS',
        },
        {
          name: 'specialOption',
          label: 'Special options',
        },
        {
          name: 'tags',
          label: 'Tags',
        },
        {
          name: 'account_id',
          label: 'Account number',
        },
        {
          name: 'parent_id',
          label: 'Parent number',
        },
        {
          name: 'order_id',
          label: 'Order number',
        },
        {
          name: 'deposit',
          label: 'Deposit',
        },
        {
          name: 'balance',
          label: 'Balance',
        },
        {
          name: 'createdAt',
          label: 'Created at',
          sortable: true,
        },
        {
          name: 'start_datetime',
          label: 'Start date',
          sortable: true,
        },
        {
          name: 'end_datetime',
          label: 'End date',
          sortable: true,
        },
        {
          name: 'close_datetime',
          label: 'Close date',
          sortable: true,
        },
        {
          name: 'state', label: 'State',
        },
      ]
    },
  },
}
</script>
